import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioModel } from 'src/app/models/usuariomodels';
import { AuthService } from 'src/app/services/auth.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
//import { map } from 'rxjs/operators';
@Component({
  selector: 'app-editarusuario',
  templateUrl: './editarusuario.component.html',
  
})
export class EditarusuarioComponent implements OnInit {

  terminos=false;
  condicion=false;
  autenticado:boolean;
  usuario: UsuarioModel;
  constructor(private auth:AuthService, private router:Router) {
    this.usuario=this.auth.crearProfe();
    this.auth.confirmaUsuario().subscribe(resp=>{

      console.log(resp);
      this.autenticado=resp;
      if(!this.autenticado){
        this.router.navigateByUrl('/usuario')
      }
      
   },(err)=>{
    console.log(err.error.error.message);
   });

   }

  ngOnInit() {
    //this.usuario=this.auth.crearProfe();
    
   /*  this.auth.getUsuario().subscribe(
      resp=>{
        //this.usuario=resp[0];
        console.log(resp);
        this.usuario.nombre=resp['nombre'];
        /* if(resp['bio']){
          this.usuario.bio=resp['bio'];
        }
        if(resp['apellido']){
          this.usuario.apellido=resp['apellido'];
        }
        

        this.usuario.cel=resp['cel'];
        this.usuario.email=resp['email']; 

      }
    ); */
    //this.usuario.nombre="Diego Alexander";
  }
  //usuario.nombre="Diego Alexander";
  //imagenes: any[]=[];
  cargarImagen(event:any){
   
    let archivos = event.target.files;
    let reader= new FileReader();
    let id=localStorage.getItem('id');
    let link:string;
    reader.readAsDataURL(archivos[0]);
    reader.onloadend=()=>{
     //this.auth.subirImagen(reader.result).subscribe();
    // this.imagenes.push(reader.result);
    Swal.fire({ 
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando imagen espera por Favor..'});
      Swal.showLoading();
     this.auth.subirImagen(id,reader.result).then(resp=>{
       console.log(resp);
       
       link=String(resp);
       this.auth.editaUrl(link).subscribe(resp=>{
        Swal.close()},(err)=>{
          console.log(err.error.error.message);
          Swal.fire({ 
        
            icon: 'error',
            title:'Error al autenticar',
          
            text: err.error.error.message});
          }
       );
       
       //localStorage.clear();
       
       //localStorage.setItem('urlPhoto',link);
       //console.log(link);
     /* this.auth.llenaUsuario().subscribe(resp2=>{
        console.log(resp2);
      }); */




      });
      
   
    } 


  }
onSubmit(form:NgForm){
  
  if(form.invalid || !this.terminos){
    this.condicion=true;
    return;
  }
  Swal.fire({ 
    allowOutsideClick: false,
    icon: 'info',
    text: 'Espera por Favor..'});
    Swal.showLoading();
 // console.log("en el post");
  //console.log(this.usuario);
  //console.log(form)
  this.auth.editaUsuario(this.usuario).subscribe(resp=>{
   // console.log(resp);
   this.auth.getUsuario().subscribe(resp2=>{
    Swal.close();
    this.router.navigateByUrl('/usuario');
   }
   );
   
    //this.condicion=false;
   // this.auth.crearProfesor(this.usuario).subscribe(resp2=>{

    //})
    //this.usuario=this.auth.crearProfe();

    
  },(err)=>{
    console.log(err.error.error.message);
    let texto:string;
    
      texto=err.error.error.message;
   
    Swal.fire({ 
      
      icon: 'error',
      title:'Error al actualizar',
      
      text: texto});
      
  
    
  }
  );
}

}
