import { Component, OnInit} from '@angular/core';
//import { HeroesService, Heroe } from '../../servicios/heroes.service';
import {  Router } from '@angular/router';
//import { element } from 'protractor';
import { AuthService } from '../../services/auth.service';
import { UsuarioModel } from '../../models/usuariomodels';

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html',
  
})
export class HeroesComponent implements OnInit {
  p: number = 1;
  
  //heroes:Heroe[]=[];
  //usuarios:any =[];
  heroes:UsuarioModel[] =[];
  constructor(  private router:Router,private auth: AuthService ) { 
    if(localStorage.getItem('pagina')){
      this.p=Number(localStorage.getItem('pagina'));
    }else{
      this.p=1;
    }
   
  }

  ngOnInit() {
   
   
    this.auth.getProfes().subscribe(resp=>{
      this.heroes=resp;
    });
    //this.usuarios=this.auth.getProfes().subscribe();
    //console.log(this.usuarios);
    //console.log(this.heroes);
  }
  buscarCiudad(ciu:string){
    //console.log(ciu);
    //this.heroes=this._heroesService.getHeroesCiudad('ciu');
    localStorage.setItem('ciu',ciu);
    localStorage.removeItem('pagina');
    this.p=1;
    this.router.navigate(['/profesores-de-matematicas-en', ciu]);
  }

  pageChanged(event){
    //console.log("pageChanged");
    this.p = event;
    let pag=String(this.p);
    //console.log(this.p);
    localStorage.setItem('pagina',pag);
    window.scroll(0,0);
  }
}
