import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
  
})
export class NavbarComponent implements OnInit {
  mostrar: boolean;
  constructor(private auth:AuthService, private router: Router) { 
    this.preguntaAutenticacion();
  }

  ngOnInit() {
    
  }
  salir(){
    this.auth.logout();
   
    this.router.navigateByUrl('/ingresar');
    

  }
preguntaAutenticacion(){
  if(this.auth.estaAutenticado()){
    this.mostrar= true;
  } else{
    
    this.mostrar= false;
  }
}
}
