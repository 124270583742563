import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsuarioModel } from '../../models/usuariomodels';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ingresar',
  templateUrl: './ingresar.component.html',
  
})
export class IngresarComponent implements OnInit {

  
  usuario: UsuarioModel;
  constructor(private auth:AuthService, private router:Router) { }

  ngOnInit() {
    this.usuario=new UsuarioModel;
    if(localStorage.getItem('email')){
      this.usuario.email=localStorage.getItem('email');
      
    }
  }
onSubmit(form:NgForm){
  
  if(form.invalid ){
    return;
  }
  
  Swal.fire({ 
    allowOutsideClick: false,
    icon: 'info',
    text: 'Ingresando espera por Favor..'});
    Swal.showLoading();
   
      
  //console.log("en el post");
  //console.log(this.usuario);
  //console.log(form);
  
  this.auth.login(this.usuario).subscribe(resp=>{
   // console.log(resp);
  

    this.auth.getUsuario().subscribe(resp2=>{
      Swal.close();
     // console.log(resp2);
      
      this.router.navigateByUrl('/usuario');
    
     },(err)=>{
       console.log(err);
      Swal.fire({ 
      
        icon: 'error',
        title:'Error al obtener datos',
        
        text: 'Error al obtener datos'});
     });
    
    
  },(err)=>{
    console.log(err.error.error.message);
    let texto:string;
    if(err.error.error.message=='EMAIL_NOT_FOUND'){
      texto='Usuario no registrado';
    }
    else if(err.error.error.message=='INVALID_PASSWORD'){
      texto='Contraseña invalida';
    }
    else if(err.error.error.message=='INVALID_EMAIL'){
      texto='El email no es valido';
    }
    else{
      texto=err.error.error.message;
    }
    Swal.fire({ 
      
      icon: 'error',
      title:'Error al autenticar',
      
      text: texto});
      
  }
  );
}
 async recuperarcontra(){
  const { value: email } = await Swal.fire({ 
   
     input:'email', 
     inputPlaceholder:'example@email.com',
    icon: 'question',
    title:'Ingresa tu correo electrónico',
    showCancelButton: true,
    
  
  });
  if (email) {
    this.auth.recuperaCorreo(email).subscribe(resp=>{
     // console.log(resp);
      Swal.fire(`Revisa tu correo: ${email}`)
    },(err)=>{
      console.log(err.error.error.message);
      let texto:string;
      if(err.error.error.message=='EMAIL_NOT_FOUND'){
        texto='Usuario no registrado o ha sido eliminado';
      }
      
      else{
        texto=err.error.error.message;
      }
      Swal.fire({ 
        
        icon: 'error',
        title:'Error ',
        
        text: texto});
        
    });
    
  }

 };
  
 
 
 
    

}
