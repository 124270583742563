import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';

import { Location } from '@angular/common'
import { AuthService } from '../../services/auth.service';
import { UsuarioModel } from '../../models/usuariomodels';

@Component({
  selector: 'app-heroe',
  templateUrl: './heroe.component.html'
  
})
export class HeroeComponent {
  heroe: any={};
  codigo:string;
 // heroe: UsuarioModel;
  constructor(private activatedRoute:ActivatedRoute,
              private location: Location,
              private auth:AuthService,
              private router:Router
    ) {
      //this.heroe=this.auth.getProfes();
   
   }
   ngOnInit() {
     this.activatedRoute.params.subscribe(params=>{
      this.codigo=params['id'];
      this.auth.getProfe2(params['id']).subscribe(resp=>{
    this.heroe=resp;
      },(err)=>{
        console.log(err);
      
     });
      //this.heroe=this._heroesService.getHeroe(params['id']);
       // console.log(this.heroe);
       //console.log(params['id']);
     })
   }
 
 
   back(): void {
    this.location.back()
  }

}
