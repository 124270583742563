import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { UsuarioModel } from '../../models/usuariomodels';
@Component({
  selector: 'app-ciudad',
  templateUrl: './ciudad.component.html',
  
})

export class CiudadComponent implements OnInit {
  
  
  p: number;
  heroes:UsuarioModel[]=[];
  ciudad:string;
  constructor(private auth: AuthService, private activatedRoute:ActivatedRoute,  private router:Router ) { 
  if(localStorage.getItem('pagina')){
    this.p=Number(localStorage.getItem('pagina'));
  }else{
    this.p=1;
  }
  
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
     // console.log(params['ciudad']);
      this.auth.getProfesCiudad(params['ciudad']).subscribe(
      resp=>{

        this.heroes=resp;
      });
      this.ciudad=params['ciudad'];

    }
    

      
      )
    
    //console.log(this.heroes);
    //let ciud=document.getElementById('ciu');
   //let ciu = (<HTMLSelectElement>document.getElementById('ciu')).value;
    //onsole.log(ciu)
    //this.heroes=this._heroesService.getHeroesCiudad(ciu); 
    
    
  }
  pageChanged(event){
    //console.log("pageChanged");
    this.p = event;
    let pag=String(this.p);
    //console.log(this.p);
    localStorage.setItem('pagina',pag);
    window.scroll(0,0);
  }

  buscarCiudad(ciu:string){
    //console.log(ciu);
    //this.heroes=this._heroesService.getHeroesCiudad('ciu');
    localStorage.removeItem('pagina');
    this.p=1;
    this.router.navigate(['/profesores-de-matematicas-en', ciu]);
  }
    //let ciud=document.getElementById('ciu');
    //let ciu=ciud.ariaValueMin;
    //this.heroes=this._heroesService.getHeroesCiudad(ciu); 

}