export class UsuarioModel{
    id:string;
    cel:string;
    email:string;
    password:string;
    nombre:string;
    apellido:string;
    bio:string;
    clases_virtuales:boolean;
    clases_presenciales:boolean;
    estado:boolean;
    disponible:boolean;
    ciudad:string;
    urlPhoto:string;
    puntos:number;
    
    
    constructor(){
        this.estado=false;
        this.urlPhoto="Nofound";
        this.puntos=2;
    }
}
