import { RouterModule,Routes } from "@angular/router";
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from "./components/about/about.component";
import { HeroesComponent } from "./components/heroes/heroes.component";
import { HeroeComponent } from "./components/heroe/heroe.component";
import { CiudadComponent } from "./components/ciudad/ciudad.component";
import { RegistroComponent } from "./components/registro/registro.component";
import { PoliticaComponent } from "./components/politica/politica.component";
import { UsuarioComponent } from "./components/usuario/usuario.component";
import { EditarusuarioComponent } from './components/editarusuario/editarusuario.component';
import { Politicas2Component } from "./components/politicas2/politicas2.component";

import { IngresarComponent } from './components/ingresar/ingresar.component';
import { AuthGuard } from "./guards/auth.guard";


const APP_ROUTES: Routes=[
    {path: 'home', component:HomeComponent},
    {path: 'about', component:AboutComponent},
    {path:'profesores-de-matematicas',component:HeroesComponent},
    {path:'profesor/:id', component:HeroeComponent},
    {path:'profesores-de-matematicas-en/:ciudad', component:CiudadComponent},
    {path: 'registro', component:RegistroComponent},
    {path: 'ingresar', component:IngresarComponent},
    {path: 'politicas', component:PoliticaComponent},
    {path: 'politicas2', component:Politicas2Component},
    {path: 'usuario',component:UsuarioComponent,canActivate:[AuthGuard]},
    {path: 'editarusuario', component:EditarusuarioComponent, canActivate:[AuthGuard]},
    {path:'**',pathMatch:'full', redirectTo:'home'}
]
export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
