import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',

})
export class HomeComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  buscarCiudad(ciu:string){
    //console.log(ciu);
    //this.heroes=this._heroesService.getHeroesCiudad('ciu');
    this.router.navigate(['/profesores-de-matematicas-en', ciu]);
  }
}
