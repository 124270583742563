import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioModel } from '../models/usuariomodels';
import { map} from 'rxjs/operators';
//import {AngularFireAuth} from '@angular/fire/compat/auth';
//import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
//import {getAuth, updateProfile} from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
//import storage from 'firebase/compat';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

//import { User } from '@firebase/auth';


firebase.initializeApp(environment.firebaseConfig);
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  //stora=firebase.app().storage().ref()
 storareRef=firebase.app().storage().ref();
  private url='https://identitytoolkit.googleapis.com/v1';
  private apikey='AIzaSyBE5jNv0wJfzVScmIGWU66emnP0fsb3mko';
  private buckets='suprofe-40e79.appspot.com';
  userToken:string;
  uid='';
  private urldb='https://suprofe-40e79-default-rtdb.firebaseio.com';
//crear nuevo usuario 
// https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]

//loguin
// https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=[API_KEY]

//Actualizar perfil
//https://identitytoolkit.googleapis.com/v1/accounts:update?key=[API_KEY]
 
constructor(private http:HttpClient , private router:Router) { 
    this.leerToken();
  }




  async subirImagen(nombre:string, imgbase64:any){
  try{
let respuesta = await this.storareRef.child("users/"+nombre).putString(imgbase64,'data_url');
//console.log(respuesta);
//funcion para insertar url en la base de datoss
 //this.editaUrl(respuesta.ref.getDownloadURL()).subscribe();
return await respuesta.ref.getDownloadURL();
  }catch(err){
    console.log(err);
    return null;
  }
}  











  logout(){
    
    const em=localStorage.getItem('email');
    const re=Boolean(localStorage.getItem('recordar'));
    localStorage.clear();
    if(re){
      localStorage.setItem('email',em);
    }
   
  }
/* 
  async login2(usuario:UsuarioModel){
    try {
      return await this.afauth.signInWithEmailAndPassword(usuario.email,usuario.password);
    }catch(err){
      console.log(err);
      return null;
    }

    


    
    
  }*/
  confirmaCorreo(code:string){
    const authData={
      oobCode:code
    };
    return this.http.post(`${this.url}/accounts:update?key=${this.apikey}`,authData
    ).pipe(
      map(resp=>{
       // console.log('entro en el mapa rxjs');
       // this.guardarToken(resp['idToken']);
        //this.guardarUid(resp['localId']);
        //localStorage.setItem('verificatedemail',uid);
       // console.log(resp);
        return resp;
      }));

  }
  enviaCorreo(){
    if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
    }else{
      this.userToken='';
    }
    const authData={
      requestType:"VERIFY_EMAIL",
      idToken:this.userToken
    };
    return this.http.post(`${this.url}/accounts:sendOobCode?key=${this.apikey}`,authData
    ).pipe(
      map(resp=>{
       // console.log('entro en el mapa rxjs');
       // this.guardarToken(resp['idToken']);
        //this.guardarUid(resp['localId']);
        //localStorage.setItem('verificatedemail',uid);
        //console.log(resp);
        return resp;
      }));

  }

  recuperaCorreo(correo){
    
    const authData={
      requestType:"PASSWORD_RESET",
      email:correo
      
    };
    return this.http.post(`${this.url}/accounts:sendOobCode?key=${this.apikey}`,authData
    ).pipe(
      map(resp=>{
       // console.log('resp');
        
        return resp;
      }));

  }

  login(usuario:UsuarioModel){
    
      const authData={
        email:usuario.email,
        password: usuario.password,
        returnSecureToken: true
      };
      return this.http.post(`${this.url}/accounts:signInWithPassword?key=${this.apikey}`,authData
      ).pipe(
        map(resp=>{
         // console.log('resp');
          this.guardarToken(resp['idToken']);
          this.guardarUid(resp['localId']);
          return resp;
        }));
  
    }
  
  /* nuevoUsuario(usuario:UsuarioModel){
    const authData={
      email:usuario.email,
      password: usuario.password,
      returnSecureToken: true
    };
    return this.http.post(`${this.url}/accounts:signUp?key=${this.apikey}`,authData
    ).pipe(
      map(resp=>{
        console.log('entro en el mapa rxjs');
        const Uid= resp['localId'];
        this.http.post(`${this.urldb}/profesores/${Uid}.json`,usuario).subscribe();
        this.guardarToken(resp['idToken'])
        return resp;
      })
    );

  } */
  envioMail(usuario:UsuarioModel){
    const Data={
      email:usuario.email,
      nombre: usuario.password,
      cel: usuario.cel,
      materia:usuario.bio
    };
    return this.http.post(`http://www.suprofe.com/enviar.php`,Data)
  }


   nuevoUsuario(usuario:UsuarioModel){
    const authData={
      email:usuario.email,
      password: usuario.password,
      returnSecureToken: true
    };
    return this.http.post(`${this.url}/accounts:signUp?key=${this.apikey}`,authData
    ).pipe(
      map(resp=>{
        localStorage.setItem('nombre',usuario.nombre);
        localStorage.setItem('cel',usuario.cel);
       // console.log('entro en el mapa de la funcion nuevo usuario');
        const Uid= resp['localId'];
        //this.http.post(`${this.urldb}/profesores/.json?auth=${Uid}`,usuario).subscribe();
        this.guardarToken(resp['idToken']);
        this.guardarUid(resp['localId']);
        this.editaDbRegistro(usuario,resp['idToken'],resp['localId']).subscribe();
        return resp;
      })
    );


  } 
  confirmaUsuario(){
    if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
    }else{
      this.userToken='';
    }
    const user={
      idToken:this.userToken
     
      
      
    };
    return this.http.post(`${this.url}/accounts:lookup?key=${this.apikey}`,user
      ).pipe(
        map(resp=>{
          const ob1:object=resp['users'];
          const ob2:object=ob1[0];


          return ob2['emailVerified'];
          
     

         
          /* Object.keys(resp['users']).forEach(
            key=>{
              console.log(key);
            }); */
          //return this.crearArreglo(resp['users[0]']);;
        }));
  
  }
  subirImagen2(img: any){
    return this.http.post(`https://firebasestorage.googleapis.com/v1beta/${this.buckets}:addFirebase`, img); 
  }
  


  getUsuario(){
    if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
      this.uid=localStorage.getItem('id');
    }else{
      this.userToken='';
    }
    return this.http.get(`${this.urldb}/profesores/${this.uid}.json`).pipe(
      map(resp=>{
        

        return this.crearArreglo(resp);
      }));
  }

  private crearArreglo(userOb:object){
    let user:any=[{}];
     if(userOb== null){
       return"";
     }
     
     Object.keys(userOb).forEach(
      key=>{
        const propi:string= userOb[key];
       
        user[key]= userOb[key]; 
       localStorage.setItem(key,userOb[key]);
        
        
      }
    ); 
     return user;
     
   }
   private crearArreglo2(userOb:object){
    let user:any=[{}];
     if(userOb== null){
       return"";
     }
     
     Object.keys(userOb).forEach(
      key=>{
        const propi:string= userOb[key];
       
        user[key]= userOb[key]; 
       //localStorage.setItem(key,userOb[key]);
        
        
      }
    ); 
     return user;
     
   }

   crearProfe(){
     let profe= new UsuarioModel;
     
      profe.apellido=localStorage.getItem('apellido');
     profe.bio=localStorage.getItem('bio');
     profe.cel=localStorage.getItem('cel');
     profe.ciudad=localStorage.getItem('ciudad');
     if(localStorage.getItem('disponible')=='true'){
      profe.disponible=true
     }
     if(localStorage.getItem('clases_presenciales')=='true'){
      profe.clases_presenciales=true
     }
     if(localStorage.getItem('clases_virtuales')=='true'){
      profe.clases_virtuales=true
     }
     profe.email=localStorage.getItem('email');
     profe.estado=Boolean (localStorage.getItem('estado'));
     profe.nombre=localStorage.getItem('nombre');
     profe.urlPhoto=localStorage.getItem('urlPhoto'); 

    return profe;

   }

  /* private crearArreglo(userOb:object){
    let user = new UsuarioModel;
     console.log(userOb); 
     if(userOb== null){
       return"";
     }
     
     Object.keys(userOb).forEach(
      key=>{
        const propi:string= userOb[key];
       
        switch(key){
          case "apellido":
            {user.apellido=propi;
            break;}
          case  "bio":
           { user.bio=propi;
            break;}
          case 'cel':
            {user.cel=propi;
              break;}
          
            
          case "ciudad":
            {user.ciudad=propi;
              break;}
          case "clases_presenciales":
            {user.clases_presenciales= userOb[key];
              break;}
          case  "clases_virtuales":
           { user.clases_virtuales= userOb[key];
            break;}
          case "email":
           { user.email=userOb[key];
            break;}
          case "estado":
            {user.estado=userOb[key];
              break;}
          
          case  "nombre":
           { user.nombre=propi;
            break;}
          case "password":
            {user.password=propi;
              break;}
          case "urlPhoto":
            {user.urlPhoto=propi;
              break;}
          

        }
        
        
      }
    ); 
     
    // Object.assign(userOb,user);
     return user;
     
   } */
   getProfesCiudad(ci:string){
    let users:UsuarioModel[]=[];
  
    return this.http.get(`${this.urldb}/profesores.json`).pipe(
      map(resp=>{
        users=this.crearArreglos2(resp,ci);

        return users;
      }));
  }

   getProfes(){
    let users:UsuarioModel[]=[];
   /*  if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
      //this.uid=localStorage.getItem('id');
    }else{
      this.userToken='';
    } */
    
    return this.http.get(`${this.urldb}/profesores.json`).pipe(
      map(resp=>{
        users=this.crearArreglos(resp);
 // return resp
        return users;
      }));
  }
  private crearArreglos(userOb:object){
     const users:UsuarioModel[]=[];
     // console.log(userOb); 
    if(userOb== null){
      return[];
    }
    Object.keys(userOb).forEach(
      key=>{
        const user:UsuarioModel= userOb[key];
        if(user.estado&&user.disponible){
          users.push(user);
         }
          
        
      }
    );
    const users2:UsuarioModel[]= users.sort((a, b) =>{
      if (a.puntos > b.puntos) {
        return -1;
      }
      if (a.puntos < b.puntos) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
     
    return users2;


  } 
  private crearArreglos2(userOb:object,ciu:string){
    const users:UsuarioModel[]=[];
     //console.log(userOb); 
   if(userOb== null){
     return[];
   }
   Object.keys(userOb).forEach(
     key=>{
       const user:UsuarioModel= userOb[key];
       if(user.ciudad==ciu&&user.estado&&user.disponible){
        users.push(user);
       }
       
     }
   );
 const users2:UsuarioModel[]= users.sort((a, b) =>{
    if (a.puntos > b.puntos) {
      return -1;
    }
    if (a.puntos < b.puntos) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });
   
  return users2;


 } 

  editaUsuario(usuario:UsuarioModel){
    if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
      this.uid=localStorage.getItem('id');
    }else{
      this.userToken='';
    }
    const arg={
      cel:usuario.cel,
      urlPhoto:localStorage.getItem('urlPhoto'),
      id:this.uid,
      nombre:usuario.nombre,
      apellido:usuario.apellido,
      bio:usuario.bio,
      disponible:usuario.disponible,
      estado:false,
      
      ciudad:usuario.ciudad,
      clases_presenciales: usuario.clases_presenciales,
      clases_virtuales: usuario.clases_virtuales,
           
      
    }; 
    return this.http.patch(`${this.urldb}/profesores/${this.uid}.json?auth=${this.userToken}`,arg).pipe(
      map(resp=>{
        
       
       // console.log(resp,this.userToken);
        return resp;
      }));

  }








  editaUrl(url:string){
    if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
      this.uid=localStorage.getItem('id');
    }else{
      console.log('no se puede subir foto ya que falta token');
    }
    const arg={
      urlPhoto:url,
      estado:false,
      
      
    };
    return this.http.patch(`${this.urldb}/profesores/${this.uid}.json?auth=${this.userToken}`,arg).pipe(
      map(resp=>{
       // console.log('Se cambio la url de la foto');
      localStorage.setItem('urlPhoto',resp['urlPhoto']);
        //console.log(resp);
        return resp;
      }));

  }
  editaDbRegistro(usuario:UsuarioModel,token:string,id:string){
    
    const authData={
    //id:usuario.id,
    cel:usuario.cel,
    email:usuario.email,
    puntos:2,
    nombre:usuario.nombre,
    apellido:usuario.apellido,
    bio:usuario.bio,
    diponibe:false,
    clases_virtuales:false,
    clases_presenciales:false,
    estado:false,
    ciudad:usuario.ciudad,
    urlPhoto:usuario.urlPhoto,
      
      
    }; 
    /* if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
      this.uid=localStorage.getItem('id');
    }else{
      this.userToken='';
    }
    const user={
      idToken:this.userToken
     }; */
   return this.http.put(`${this.urldb}/profesores/${id}.json?auth=${token}`,authData).pipe(
        map(resp=>{
        //  console.log('llenandodb desde Registro');
         
          //console.log(resp,this.userToken);
          return resp;
        }));
  
  }
  llenaUsuario(){
    if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
    }else{
      this.userToken='';
    }
    const userData={
      idToken:this.userToken,
      displayName:'Nombre Actualizado',
      photoUrl:'url.....',
      returnSecureToken:false,
      
    };
    return this.http.post(`${this.url}/accounts:update?key=${this.apikey}`,userData
      ).pipe(
        map(resp=>{
        //  console.log('llenaUsuario');
          //console.log(resp);
          return resp;
        }));
  
  }
  /* nuevoUsuario(usuario:UsuarioModel){
    const authData={
      email:usuario.email,
      password: usuario.password,
      returnSecureToken: true
    };
    
    return this.http.post(`${this.url}/accounts:signUp?key=${this.apikey}`,authData
    ).pipe(
      map(resp=>{
        console.log('entro en el mapa rxjs');
        const Uid= resp['localId'];
        
        this.guardarToken(resp['idToken']);
        const userData={
          idToken:resp['idToken'],
          displayName:'Nombre Actualizado',
          photoUrl:'url.....',
          returnSecureToken:true,
          
        };
        this.http.post(`${this.url}/accounts:update?key=${this.apikey}`,userData).pipe(map(resp2=>{console.log(resp2)}));
        
      })
    );

  } */
 getProfe2(id:string){
 // console.log(`${this.urldb}/profesores/${id}.json`);
  return this.http.get(`${this.urldb}/profesores/${id}.json`).pipe(
    map(resp=>{
     

      return this.crearArreglo2(resp);
    }));
  
}
  



  
  private guardarUid(uid: string){
    localStorage.setItem('id',uid);
  }
  private guardarToken(idToken: string){
    this.userToken=idToken;
    localStorage.setItem('token',idToken);
    let hoy = new Date();
    hoy.setSeconds(3600);
    localStorage.setItem('expira',hoy.getTime().toString());
    

  }
  leerToken(){
    if (localStorage.getItem('token')){
      this.userToken=localStorage.getItem('token');
    }else{
      this.userToken='';
    }
  }

  estaAutenticado():boolean{
    if(this.userToken.length<2){
      return false;
    }
    const expira =Number(localStorage.getItem('expira'));
    const expiraDate =new Date();
    expiraDate.setTime(expira);
    if (expiraDate > new Date()){
      return true;
    }else{
      return false;
    }
  }

}
