import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { FormsModule} from '@angular/forms';
//mport { ImageCropperModule } from 'ngx-image-cropper';
//import { ScrollingModule } from '@angular/cdk/scrolling';
//rutas
import { APP_ROUTING } from './app.routes';
import {HttpClientModule} from '@angular/common/http';
//import { ScrollingModule } from '@angular/cdk/scrolling';
//servicios
//import { HeroesService } from './servicios/heroes.service';
//componentes
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { HeroesComponent } from './components/heroes/heroes.component';
import { HeroeComponent } from './components/heroe/heroe.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { CiudadComponent } from './components/ciudad/ciudad.component';
import { RegistroComponent } from './components/registro/registro.component';
import { IngresarComponent } from './components/ingresar/ingresar.component';
import { PoliticaComponent } from './components/politica/politica.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { NavusuarioComponent } from './components/shared/navusuario/navusuario.component';
import { EditarusuarioComponent } from './components/editarusuario/editarusuario.component';
import { Politicas2Component } from './components/politicas2/politicas2.component';

//firebase
//import { initializeApp } from 'firebase/app';
///import {AngularFireModule} from '@angular/fire/compat';
//import { environment } from 'src/environments/environment';
//import { getAnalytics } from "firebase/analytics";
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    HeroesComponent,
    HeroeComponent,
    FooterComponent,
    CiudadComponent,
    RegistroComponent,
    IngresarComponent,
    PoliticaComponent,
    UsuarioComponent,
    NavusuarioComponent,
    EditarusuarioComponent,
    Politicas2Component,

  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
   // ScrollingModule,
    APP_ROUTING,
    FormsModule,
    HttpClientModule,
 //  ImageCropperModule,
    
   // AngularFireModule.initializeApp(environment.firebaseConfig),
    
    
  ],
  providers: [
    //HeroesService  //aqui van todos los servicios
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
