import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas2',
  templateUrl: './politicas2.component.html',
 
})
export class Politicas2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
