import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navusuario',
  templateUrl: './navusuario.component.html',
  
})
export class NavusuarioComponent implements OnInit {

  constructor(private auth:AuthService, private router: Router) {
    /* this.auth.getUsuario().subscribe(resp=>{
      console.log(resp);
     }); */
   }

  ngOnInit() {
   
  }
  salir(){
    this.auth.logout();
   
    this.router.navigateByUrl('/ingresar');
    

  }
}
