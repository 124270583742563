import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

//mport { map} from 'rxjs/operators';
import { UsuarioModel } from '../../models/usuariomodels';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  
})
export class UsuarioComponent implements OnInit {
  heroe:UsuarioModel;
  autenticado:boolean;
  revision:boolean=false;
  foto:boolean=false;
  bio:boolean=false; 
  ciu:boolean=false; 
  disp:boolean=false; 
  constructor(private auth:AuthService,private activatedRoute:ActivatedRoute,  private router:Router) {
    this.heroe=this.auth.crearProfe();
    //console.log(localStorage.getItem('urlPhoto'));
    if(localStorage.getItem('ciudad')){
      this.ciu=true;
     }
   if(localStorage.getItem('urlPhoto')&& localStorage.getItem('urlPhoto')!='Nofound' ){
    this.foto=true;
   }
   if(localStorage.getItem('disponible')=='true' ){
    this.disp=true;
   }
   if(localStorage.getItem('bio')){

    this.bio=true;
     }
    if(localStorage.getItem('estado')=='true'){

  this.revision=true;
   }
    
    /* this.auth.enviaCorreo().subscribe(resp=>{
      console.log(resp);
    }); */
    this.auth.confirmaUsuario().subscribe(resp=>{
      //console.log(resp);
      this.autenticado=resp;
   },(err)=>{
    console.log(err.error.error.message);
   });
   /* this.auth.llenaUsuario().subscribe(resp=>{
      console.log(resp);
   },(err)=>{
    console.log(err.error.error.message);
   }); */
   /* this.auth.etaDb().subscribe(); */ 
    /* this.auth.getUsuario().subscribe(resp=>{
     console.log(resp);
    }); */
   // let profe=this.auth.crearProfe();
    //console.log(profe);
    //this.heroe=profe;
   /*  this.activatedRoute.params.subscribe(params=>{
    this.heroe=this._heroesService.getHeroe('6');
    } )*/
  } 
   reenvio() {
    this.auth.enviaCorreo().subscribe(resp=>{
      //console.log(resp);
    },(err)=>{
      console.log(err.error.error.message);
     });
    
  }
     
    
   ngOnInit() {}
   

}
