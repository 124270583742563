import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsuarioModel } from '../../models/usuariomodels';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {map} from 'rxjs/operators';
@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  
})
export class RegistroComponent implements OnInit {
  terminos=false;
  usuario: UsuarioModel;
  constructor(private auth:AuthService, private router:Router) { }

  ngOnInit() {
    this.usuario=new UsuarioModel;
  }
onSubmit(form:NgForm){
  
    if(form.invalid || !this.terminos){
        return;
    }
    Swal.fire({ 
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espera por Favor..'});
      Swal.showLoading();
       // console.log("en el post");
      //console.log(this.usuario);
      //console.log(form)
      this.auth.nuevoUsuario(this.usuario).subscribe(resp=>{
        Swal.close();
       // console.log(resp);
        //this.auth.getUsuario().subscribe();
        this.router.navigateByUrl('/editarusuario');
          },(err)=>{
          console.log(err.error.error.message);
          let texto:string;
          if(err.error.error.message=='INVALID_EMAIL'){
          texto='El email no es valido';
          }
          else if(err.error.error.message=='EMAIL_EXISTS'){
          texto='El usuario ya esta registrado';
          }
  
          else{
          texto=err.error.error.message;
          }
          Swal.fire({ 
        
          icon: 'error',
          title:'Error al autenticar',
        
          text: texto});
        
    
      
        }
      );
    
}

}
